import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Subscription({ size, color, title, ariaHidden }: ComponentProps) {
  return (
    <>
      <BaseSvgStyle
        size={size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden={ariaHidden}
      >
        {title && <title>{title}</title>}
        <mask
          xmlns="http://www.w3.org/2000/svg"
          id="path-1-inside-1_7467_28805"
          fill="white"
        >
          <path d="M12.0095 20.4995C10.8295 20.4995 9.72951 20.2795 8.68951 19.8295C7.65951 19.3795 6.75951 18.7795 5.98951 18.0095C5.21951 17.2395 4.61951 16.3395 4.16951 15.3095C3.71951 14.2795 3.49951 13.1695 3.49951 11.9895C3.49951 10.8095 3.71951 9.70949 4.16951 8.66949C4.61951 7.62949 5.21951 6.73949 5.98951 5.96949C6.75951 5.19949 7.65951 4.59949 8.68951 4.14949C9.71951 3.69949 10.8295 3.47949 12.0095 3.47949C13.2695 3.47949 14.4595 3.73949 15.5895 4.26949C16.7195 4.79949 17.6895 5.53949 18.5095 6.49949V4.87949C18.5095 4.46949 18.8495 4.12949 19.2595 4.12949C19.6695 4.12949 20.0095 4.46949 20.0095 4.87949V8.20949C20.0095 8.75949 19.5595 9.20949 19.0095 9.20949H15.6795C15.2695 9.20949 14.9295 8.86949 14.9295 8.45949C14.9295 8.04949 15.2695 7.70949 15.6795 7.70949H17.5495C16.8595 6.85949 16.0395 6.18949 15.0795 5.70949C14.1195 5.21949 13.0995 4.97949 12.0095 4.97949C10.0595 4.97949 8.40951 5.65949 7.04951 7.01949C5.68951 8.37949 5.00951 10.0295 5.00951 11.9795C5.00951 13.9295 5.68951 15.5795 7.04951 16.9395C8.40951 18.2995 10.0595 18.9795 12.0095 18.9795C13.7595 18.9795 15.2895 18.4095 16.5895 17.2795C17.7295 16.2795 18.4695 15.0595 18.8095 13.5995C18.8895 13.2395 19.1895 12.9795 19.5495 12.9795C20.0495 12.9795 20.3995 13.4395 20.2995 13.9295C19.9195 15.6595 19.0395 17.1295 17.6795 18.3395C16.0695 19.7695 14.1795 20.4795 12.0195 20.4795L12.0095 20.4995ZM14.4595 15.4995L11.5595 12.5995C11.3695 12.4095 11.2695 12.1595 11.2695 11.8895V7.74949C11.2695 7.33949 11.6095 6.99949 12.0195 6.99949C12.4295 6.99949 12.7695 7.33949 12.7695 7.74949V11.6995L15.5195 14.4495C15.8095 14.7395 15.8095 15.2095 15.5195 15.4995C15.2295 15.7895 14.7595 15.7895 14.4695 15.4995H14.4595Z" />
        </mask>
        <BaseIconPathStyle
          stroke={color}
          strokeRound
          xmlns="http://www.w3.org/2000/svg"
          d="M12.0095 20.4995C10.8295 20.4995 9.72951 20.2795 8.68951 19.8295C7.65951 19.3795 6.75951 18.7795 5.98951 18.0095C5.21951 17.2395 4.61951 16.3395 4.16951 15.3095C3.71951 14.2795 3.49951 13.1695 3.49951 11.9895C3.49951 10.8095 3.71951 9.70949 4.16951 8.66949C4.61951 7.62949 5.21951 6.73949 5.98951 5.96949C6.75951 5.19949 7.65951 4.59949 8.68951 4.14949C9.71951 3.69949 10.8295 3.47949 12.0095 3.47949C13.2695 3.47949 14.4595 3.73949 15.5895 4.26949C16.7195 4.79949 17.6895 5.53949 18.5095 6.49949V4.87949C18.5095 4.46949 18.8495 4.12949 19.2595 4.12949C19.6695 4.12949 20.0095 4.46949 20.0095 4.87949V8.20949C20.0095 8.75949 19.5595 9.20949 19.0095 9.20949H15.6795C15.2695 9.20949 14.9295 8.86949 14.9295 8.45949C14.9295 8.04949 15.2695 7.70949 15.6795 7.70949H17.5495C16.8595 6.85949 16.0395 6.18949 15.0795 5.70949C14.1195 5.21949 13.0995 4.97949 12.0095 4.97949C10.0595 4.97949 8.40951 5.65949 7.04951 7.01949C5.68951 8.37949 5.00951 10.0295 5.00951 11.9795C5.00951 13.9295 5.68951 15.5795 7.04951 16.9395C8.40951 18.2995 10.0595 18.9795 12.0095 18.9795C13.7595 18.9795 15.2895 18.4095 16.5895 17.2795C17.7295 16.2795 18.4695 15.0595 18.8095 13.5995C18.8895 13.2395 19.1895 12.9795 19.5495 12.9795C20.0495 12.9795 20.3995 13.4395 20.2995 13.9295C19.9195 15.6595 19.0395 17.1295 17.6795 18.3395C16.0695 19.7695 14.1795 20.4795 12.0195 20.4795L12.0095 20.4995ZM14.4595 15.4995L11.5595 12.5995C11.3695 12.4095 11.2695 12.1595 11.2695 11.8895V7.74949C11.2695 7.33949 11.6095 6.99949 12.0195 6.99949C12.4295 6.99949 12.7695 7.33949 12.7695 7.74949V11.6995L15.5195 14.4495C15.8095 14.7395 15.8095 15.2095 15.5195 15.4995C15.2295 15.7895 14.7595 15.7895 14.4695 15.4995H14.4595Z"
          strokeWidth="2"
          mask="url(#path-1-inside-1_7467_28805)"
        />
      </BaseSvgStyle>
    </>
  );
}

export default Subscription;
